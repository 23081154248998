.announcementContainer {
  position: absolute;
  top: 40px; /* Adjust this value to move the container down */
  right: 20px;
  background-color: rgba(255, 255, 255, 0.9);
  padding: 10px 20px;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.announcementContainer p {
  margin: 0;
  font-size: 14px;
  color: #333;
}


.LoginSignUpContainer {
    width: 100vw;
    height: 100vh;
    max-width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(231, 231, 231);
    position: relative;
    top: 0%;
    left: 0;
}

.LoginSignUpBox {
    background-color: white;
    border-radius: 20px;
    width: 25vw;
    height: 300px;
    box-sizing: border-box;
    overflow: hidden;
    position: absolute;
    justify-content: space-between;
}

.login_signUp_toggle {
    display: flex;
    height: 3vmax;
}

.login_signUp_toggle>p {
    color: rgba(0, 0, 0, 0.678);
    font: 300 1vmax "Roboto";
    transition: all 0.5s;
    cursor: pointer;
    display: grid;
    place-items: center;
    width: 100%;
}

.login_signUp_toggle>p:hover {
    color: #065749;
}

.LoginSignUpBox>div>button {
    background-color: #065749;
    height: 3px;
    width: 100%;
    border: none;
    transition: all 0.5s;
}

.loginForm,
.signUpForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: auto;
    padding: 30px 2vmax;
    justify-content: space-evenly;
    height: 60%;
    transition: all 0.5s;
}

.loginEmail>div {
    width: 100%;
}

.signUpForm {
    transform: translateY(-100%) translateX(-100vmax);
}

.loginForm>div,
.signUpForm>div {
    display: flex;
    width: 100%;
    align-items: center;
    padding-inline-start: 1vmax vmax;
    font: 300 15px "Roboto";
}

.css-b62m3t-container {
    width: inherit;
}

.loginForm>div>input,
.signUpForm>div>input {
    padding: 1vmax 4vmax;
    padding-right: 1vmax;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.267);
    border-radius: 4px;
    font: 300 15px "Roboto";
    outline: none;
}

.loginForm>div>select,
.signUpForm>div>select {
    padding: 1vmax 4vmax;
    padding-right: 1vmax;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid rgba(0, 0, 0, 0.267);
    border-radius: 4px;
    font: 300 0.9vmax "Roboto";
    outline: none;
}

.loginForm>.Hinttext,
.signUpForm>.Hinttext {
    color: #065749;
}

.loginForm>div>svg,
.signUpForm>div>svg {
    position: absolute;
    transform: translateX(1vmax);
    font-size: 1.6vmax;
    color: rgba(0, 0, 0, 0.623);
}

.loginForm>a {
    color: rgba(0, 0, 0, 0.651);
    text-decoration: none;
    align-self: flex-end;
    transition: all 0.5s;
    font: 500 0.8vmax "Roboto";
}

.loginForm>a:hover {
    color: black;
}

#registerImage>img {
    width: 3vmax;
    border-radius: 100%;
}

#registerImage>input {
    display: flex;
    padding: 0%;
}

#registerImage>input::file-selector-button {
    cursor: pointer;
    width: 100%;
    z-index: 2;
    height: 5vh;
    border: none;
    margin: 0%;
    font: 400 0.8vmax "Roboto";
    transition: all 0.5s;
    padding: 0 1vmax;
    color: rgba(0, 0, 0, 0.623);
    background-color: rgb(255, 255, 255);
}

#registerImage>input::file-selector-button:hover {
    background-color: rgb(235, 235, 235);
}

.loginForm>a {
    font: 400 15px "Roboto";
    color: rgba(0, 0, 0, 0.623);
    margin-top: 15px;
    align-items: center;
}

.loginBtn,
.signUpBtn {
    border: none;
    background-color: #065749;
    color: white;
    font: 300 0.9vmax "Roboto";
    width: 100%;
    padding: 0.8vmax;
    cursor: pointer;
    transition: all 0.5s;
    border-radius: 4px;
    outline: none;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.219);
}

.loginBtn:hover,
.signUpBtn:hover {
    background-color: rgb(179, 66, 46);
}

.shiftToLeft {
    transform: translateX(-100%);
}

.shiftToNeutral {
    transform: translateX(0%);
}

.shiftToNeutralForm {
    transform: translateX(0%) translateY(-100%);
}

.shiftToRight {
    transform: translateX(100%);
}

.left {
    display: flex;
    height: 100vh;
    flex-basis: 50%;
    background-color: #065749;
    justify-content: center;
    align-items: center;
    font: 500 1.3vmax "Roboto";
}

.right {
    display: flex;
    height: 100vh;
    flex-basis: 50%;
    background-color: rgb(231, 231, 231);
    justify-content: center;
    align-items: center;
}

.LoginSignUpContainer>.left>div {
    height: 20%;
    position: absolute;
    display: flex;
    justify-content: center;
    border-radius: 25px;
}

.LoginSignUpContainer>.left>div>a {
    height: 20%;
    position: absolute;
    display: flex;
    z-index: 200;
    justify-content: center;
    border-radius: 25px;
    /* margin-right: 60px; */
    margin-left: 220px;
    margin-right: 100px;
    padding-bottom: 120px;
    margin-top: 5px;
    color: transparent;
    z-index: 9999;
}

.LoginSignUpContainer>.left>div>img {
    border-radius: 25px;
}

.LoginSignUpContainer>.left>h3 {
    height: 40%;
    color: white;
    position: relative;
    z-index: 9999;
    font-size: 20px;
}

.LoginSignUpContainer>.left>h3>h2 {
    position: absolute;
    z-index: 9999;
    font-size: 20px;
    left: 35%;
    top: 35px;
}

.newfooter {
    font-size: 17px;
    ;
    font-weight: bold;
    z-index: 9999;
    position: absolute;
    display: flex;
    bottom: 0px;
    justify-content: center;
    align-items: center;
    left: 40%;
}

.mobileView {
    display: none !important;
}

@media screen and (max-width: 600px) {
    .LoginSignUpBox {
        width: 75vw !important;
    }
    .left {
        display: none;
    }
    .mobileView {
        display: flex !important;
        height: 300px;
        flex-basis: 50%;
        /* background-color: #065749; */
        justify-content: center;
        align-items: center;
        font: 500 1.3vmax "Roboto";
    }
    .mobileView>div {
        height: 20%;
        position: absolute;
        display: flex;
        justify-content: center;
        border-radius: 25px;
    }
    .mobileView>div>a {
        height: 20%;
        position: absolute;
        display: flex;
        z-index: 200;
        justify-content: center;
        border-radius: 25px;
        /* margin-right: 60px; */
        margin-left: 220px;
        margin-right: 100px;
        padding-bottom: 120px;
        margin-top: 5px;
        color: transparent;
        z-index: 9999;
    }
    .mobileView>div>img {
        border-radius: 25px;
    }
    .mobileView>h3 {
        height: 140%;
        color: rgb(0, 0, 0);
        position: relative;
        z-index: 9999;
        font-size: 20px;
    }
    .mobileView>h3>h2 {
        position: absolute;
        z-index: 9999;
        font-size: 20px;
        left: 35%;
        top: 35px;
    }
    .right {
        display: flex;
        height: 100vh;
        flex-basis: 100%;
        background-color: rgb(231, 231, 231);
        justify-content: center;
        align-items: center;
    }
    .newfooter {
        font-size: 10px;
        ;
        /* font-weight: bold; */
        z-index: 9999;
        position: absolute;
        display: flex;
        bottom: 0px;
        justify-content: center;
        align-items: center;
        left: 35%;
    }
    .LoginSignUpContainer {
        background-color: white;
    }
    .LoginSignUpBox {
        width: 50vw;
        height: 65vh;
    }
    .login_signUp_toggle {
        height: 5vmax;
    }
    .login_signUp_toggle>p {
        font: 300 20px "Roboto";
    }
    .loginForm,
    .signUpForm {
        padding: 5vmax;
    }
    .loginForm>div>input,
    .signUpForm>div>input {
        padding: 2.5vmax 5vmax;
        font: 300 1.7vmax "Roboto";
    }
    .loginForm>div>svg,
    .signUpForm>div>svg {
        font-size: 2.8vmax;
    }
    .loginForm>a {
        font: 500 1.8vmax "Gill Sans";
    }
    #registerImage>img {
        width: 8vmax;
        border-radius: 100%;
    }
    #registerImage>input::file-selector-button {
        height: 7vh;
        font: 400 1.8vmax "Roboto";
    }
    .loginBtn,
    .signUpBtn {
        font: 300 1.9vmax "Roboto";
        padding: 1.8vmax;
    }
}
