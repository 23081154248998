.productListContainer {
    width: 100%;
    box-sizing: border-box;
    background-color: rgb(255, 255, 255);
    border-left: 1px solid rgba(0, 0, 0, 0.158);
    display: flex;
    flex-direction: column;
    height: 100vh;
    text-align: center;
    position: absolute;
}

.btn-box>button {
    height: 100%;
}

#productListHeading {
    font: 400 2rem "Roboto";
    padding: 0.5vmax;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    transition: all 0.5s;
    margin: 2rem;
    color: black;
}

.productListTable {
    background-color: white;
    border: 1px solid white;
}

.productListTable div {
    font: 300 1vmax "Roboto";
    /* color: rgba(0, 0, 0, 0.678); */
}

.productListTable a,
.productListTable button {
    color: rgb(161, 153, 153);
    transition: all 0.5s;
}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--withRenderer,
.MuiDataGrid-root .MuiDataGrid-cell--textLeft.MuiDataGrid-cell--editing {
    justify-content: center !important;
    gap: 50px;
}

.productListTable a:hover {
    color: #065749;
}

.productListTable button {
    color: rgb(197, 40, 40);
}

.productListTable button:hover {
    color: rgb(236, 30, 30);
}

.MuiDataGrid-columnHeader div {
    color: rgb(255, 255, 255);
    column-width: 10px;
}

.MuiDataGrid-root .MuiDataGrid-cell--textLeft {
    text-align: center !important;
}

.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer {
    flex: 1;
    display: flex;
    padding: 0 6px;
    overflow: hidden;
    min-width: 0;
    white-space: nowrap;
    justify-content: center;
    flex-direction: row !important;
}

.MuiDataGrid-columnHeader {
    background-color: #065749;
    padding: 1vmax !important;
    border: 1px solid white;
    
}

.MuiTablePagination-actions {
    display: flex;
}

.MuiDataGrid-columnHeader div {
    color: rgb(255, 255, 255);
    font: 500 1.1vmax "Roboto" !important;
}

.MuiDataGrid-columnHeader svg {
    color: rgb(255, 255, 255);
    /* font: 500 1.1vmax "Roboto" !important; */
}

.MuiDataGrid-iconSeparator {
    display: none !important;
    color: rgb(255, 255, 255);
}


/* .MuiDataGrid-root .MuiDataGrid-cell {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  border-bottom: 1px solid rgba(224, 224, 224, 1);
  text-overflow: ellipsis;

} */

@media screen and (max-width: 600px) {
    .productListTable div {
        font: 300 4vw "Roboto";
    }
}