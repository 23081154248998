.reportPage {
  display: flex;
  flex-direction: column;
  justify-content: center; /* Keeps vertical alignment to the center */
  align-items: center; /* Centers content horizontally */
  min-height: 100vh; /* Full viewport height */
  padding: 20px;
  background: #f5f5f5;
  color: #333;
}

.reportTitle {
  text-align: center;
  margin-bottom: 30px;
  color: #065749;
  width: 100%; /* Ensures the title spans the full width */
}

.reportGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns of equal width */
  gap: 20px; /* Spacing between grid items */
  width: 100%; /* Adjusts grid width */
  text-align: center; /* Centers text within the grid */
  font: 300 15px "Roboto";
  
}

.reportSection {
  background: white;
  padding: 15px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0,0,0,0.1);
  min-height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center; /* Centers children horizontally */
  text-align: center; /* Centers text within the section */
  font-size: 1.5em;
 
}

.reportSection h2 {
  color: #065749;
  margin-bottom: 10px;
  font-size: 1.5em; /* Larger text for headers */
  width: 100%;
  
}

.reportSection ul {
  list-style-type: none;
  padding: 0;
  width: 100%;
  
}

.reportSection ul li {
  padding: 5px 0;
  border-bottom: 1px solid #ddd;
  font-size: 1em; /* Larger text for list items */
}

.reportSection ul li:last-child {
  border-bottom: none;
}

.printComponent {
  text-align: center;
  margin-top: 70px;
  width: 100%;
}

@media print {
  .PrintIcon {
    display: none;
  }
}
