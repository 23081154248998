.dashboard {
    width: 100vw;
    max-width: 100%;
    display: grid;
    grid-template-columns: 0.5fr 8fr;
    position: absolute;
    padding-top: 20px;
}

.dashboardSummary>hr {
    color: rgb(71, 63, 68);
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    margin-top: 30px;
    /* font: 300 1.5rem "Roboto";
  text-align: center;
  padding: 1.5rem;
  width: 100%;
  margin: 0 2rem; */
}

.dashboardContainer {
    border-left: 1px solid rgba(0, 0, 0, 0.13);
    background-color: rgb(255, 255, 255);
    padding: 0rem 0;
    width: 100vw;
}

.dashboardContainer>h1 {
    color: rgba(0, 0, 0, 0.733);
    font: 300 2.4rem "Roboto";
    text-align: center;
    width: 50%;
    padding-top: 1.5rem;
    margin: auto;
}

.dashboardSummary {
    margin: 2rem 0;
}

.dashboardSummary>div {
    display: flex;
    background-color: white;
    justify-content: center;
}

.dashboardSummary>div>p {
    background-color: #014634;
    color: white;
    font: 300 1.7rem "Roboto";
    text-align: center;
    padding: 1.5rem;
    width: 100%;
    margin: 0 2rem;
}
.dashboardSummaryBox2>a {
    color: rgb(255, 255, 255);
    font: 300 1.8rem "Roboto";
    text-align: center;
    background-color: #5e6258;
    text-decoration: none;
    padding: 1.5rem;
    width: 10vmax;
    height: 10vmax;
    margin: 2rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.dashboardSummaryBox22>a {
    color: rgb(255, 255, 255);
    font: 300 1.8rem "Roboto";
    text-align: center;
    background-color: #014634;
    text-decoration: none;
    padding: 1.5rem;
    width: 10vmax;
    height: 10vmax;
    margin: 2rem;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.dashboardSummaryBox2>a:first-child {
    background-color: #5e6258;
    color: rgb(255, 255, 255);
}

.dashboardSummaryBox2>a:last-child {
    background-color: #5e6258;
    color: rgb(255, 255, 255);
}

.lineChart {
    width: 80%;
    margin: auto;
}

.doughnutChart {
    /* width: 30vmax; */
    margin-top: 20px;
    margin: auto;
    display: flex;
    padding-left: 32px;
    padding-right: 32px;
    flex-direction: row;
}

.doughnutChart>.rightWrapper {
    margin: auto;
}

.outOfStockWrapper {
    width: auto;
    margin: auto;
    height: 300px;
    overflow: scroll;
    flex-wrap: wrap;
    display: flex;
    width: 700px;
    color: #5e6258;
    font: 600 16px "Roboto";
    margin-top: 15px;
}

.doughnutChart>.rightWrapper>p {
    color: #ad2626;
    font: 600 23px "Roboto";
    margin-top: 10px;
    text-align: center;
}

.outofStockProdWrapper {
    margin-bottom: 5px;
    /* border: #5e625821 solid 2px; */
    border-radius: 5px;
    padding: 6px;
    /* margin: 10px; */
    margin-right: 10px;
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.outofStockProd {
    margin-bottom: 5px;
    border: #5e625821 solid 2px;
    border-radius: 5px;
    padding: 6px;
    padding-top: 8px;
    padding-bottom: 8px;
    /* margin: 10px; */
    margin-right: 10px;
    width: 200px;
    display: flex;
    justify-content: space-between;
}

.outofStockProd>svg {
    color: #01463494;
}

.outofStockProd>.size {
    padding: 5px;
    background-color: #0146343a;
    border-radius: 5px;
    color: #5e6258;
    font: 600 16px "Roboto";
    margin-left: 10px;
    height: min-content;
    width: min-content;
}

.hoversizeText {
    background-color: #014634;
    padding: 2px;
}

.hovername {
    padding: 5px;
    background-color: #014634;
    border-radius: 5px;
    color: #ffffff;
    font: 400 15px "Roboto";
    margin-left: 10px;
    height: min-content;
    width: max-content;
}

.doughnutChart>.donutWrapper {
    width: 300px;
    margin-top: 20px;
    margin: auto;
}

@media screen and (max-width: 600px) {
    .dashboard {
        grid-template-columns: 1fr;
    }
    .dashboardContainer {
        border-left: none;
    }
    .dashboardContainer>h1 {
        font: 300 2rem "Roboto";
    }
    .dashboardSummary>div>p {
        margin: 0;
    }
    .dashboardSummaryBox2>a {
        padding: 0.5rem;
        margin: 1rem;
        font: 300 0.6rem "Roboto";
    }
    .doughnutChart {
        flex-direction: column;
        padding-top: 20px;
    }
    .doughnutChart>.donutWrapper {
        width: 250px;
    }
    .doughnutChart>.rightWrapper {
        padding-top: 20px;
    }
    .outOfStockWrapper {
        width: auto;
        margin: auto;
        height: 300px;
        overflow: scroll;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        /* display: flex; */
        width: 54vw;
        flex-direction: row;
        color: #5e6258;
        font: 600 16px "Roboto";
        margin-top: 15px;
    }
    .dashboardSummaryBox2>a {
        color: rgb(0, 0, 0);
        font: 300 1rem "Roboto" !important;
        text-align: center;
        background-color: #5e6258;
        text-decoration: none;
        padding: 1.5rem;
        width: 4vmax !important;
        height: 4vmax !important;
        margin: 2rem;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}

@media screen and (max-width: 1100px) {
    .dashboardSummaryBox2>a {
        color: rgb(255, 255, 255);
        font: 300 1.5rem "Roboto";
        text-align: center;
        background-color: #5e6258;
        text-decoration: none;
        padding: 1.5rem;
        width: 7vmax;
        height: 7vmax;
        margin: 2rem;
        border-radius: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }
}