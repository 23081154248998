.myOrdersPage {
    width: 100vw;
    max-width: 100%;
    padding: 0 7vmax;
    box-sizing: border-box;
    background-color: rgb(235, 235, 235);
    position: absolute;
    top: 30px;
    left: 0;
    height: 100vh;
    display: flex;
    flex-direction: column;
    margin-top: 9px;
}

.orderToggle {
    font-size: 30px;
    /* font-family: Roboto; */
    color: rgba(0, 0, 0, 0.87);
    /* outline: none; */
    /* position: relative;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    font-weight: 400;
    margin-left: auto;
    margin-right: auto;
    width: 60%; */
    /* display: flex; */
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 5px;
}

.orderToggle>fieldset {
    width: 50%;
    justify-content: center;
    display: block;
    margin-left: auto;
    margin-right: auto;
}

.orderToggle>p {
    color: rgba(0, 0, 0, 0.733);
    font: 300 25px "Roboto";
    text-align: center;
    font-weight: bold;
    width: 50%;
    padding: 10px;
    margin: auto;
}

#myOrdersHeading {
    text-align: center;
    font: 400 1.2vmax "Roboto";
    padding: 0.5vmax;
    box-sizing: border-box;
    color: rgb(255, 255, 255);
    transition: all 0.5s;
    /* background-color: rgb(44, 44, 44); */
}

.myOrdersTable {
    background-color: white;
}

.myOrdersTable div {
    font: 300 1vmax "Roboto";
    color: rgba(0, 0, 0, 0.678);
}

.myOrdersTable a {
    color: rgba(0, 0, 0, 0.527);
    transition: all 0.5s;
    align-items: center !important;
}

.MuiDataGrid-root .MuiDataGrid-cell--textRight.MuiDataGrid-cell--withRenderer,
.MuiDataGrid-root .MuiDataGrid-cell--textRight.MuiDataGrid-cell--editing {
    justify-content: center !important;
    gap: 50px;
}

.myOrdersTable a:hover {
    color: #065749;
}

.MuiDataGrid-columnHeader {
    background-color: #065749;
    padding: 1vmax !important;
    border: 1px solid white;
}

.MuiDataGrid-columnHeader div {
    color: rgb(255, 255, 255);
    font: 500 1.1vmax "Roboto" !important;
}

.MuiDataGrid-iconSeparator {
    display: none !important;
    color: rgb(255, 255, 255);
}

.MuiDataGrid-root .MuiDataGrid-cell--textRight {
    text-align: center !important;
}

@media screen and (max-width: 600px) {
    .myOrdersPage {
        width: 100vw;
        max-width: 100%;
        padding: 0 7vmax;
        box-sizing: border-box;
        background-color: rgb(235, 235, 235);
        position: fixed;
        top: 30px;
        left: 0;
        height: 100vh;
        display: flex;
        flex-direction: column;
        margin-top: 20px;
    }
    /* .myOrdersPage {
        padding: 0;
        height: 93vh;
    }
    #myOrdersHeading {
        font: 400 2.2vmax "Roboto";
        padding: 4vw;
    }
    .myOrdersTable div {
        font: 300 4vw "Roboto";
    }
    .MuiDataGrid-columnHeader {
        padding-top: 300px;
        padding: 20px !important;
    }
    .MuiDataGrid-columnHeader div {
        font: 500 5vw "Roboto" !important;
    }
    .MuiDataGrid-root MuiDataGrid-root myOrdersTable MuiDataGrid-autoHeight {
        padding-top: 300px;
    } */
    .MuiDataGrid-columnHeaderTitle {
        font: 300 3.0vw "Roboto" !important;
    }
    .myOrdersPage {
        padding: 0;
        height: 40vh;
    }
    #myOrdersHeading {
        font: 400 1.5vmax "Roboto";
        padding: 4vw;
    }
    .myOrdersTable div {
        font: 300 3.0vw "Roboto";
    }
    .MuiDataGrid-columnHeader {
        padding: 10px !important;
        font: 300 3.0vw "Roboto";
    }
    .MuiDataGrid-columnHeader div {
        font: 500 1.5vw "Roboto" !important;
    }
    .MuiDataGrid-cell div {
        font: 500 5.5vw "Roboto" !important;
    }
}