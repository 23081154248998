.player-wrapper {
    position: static;
    /* padding-top: 30.25%; */
    height: 70vh;
}

.react-player {
    position: relative;
    margin: auto;
    top: 80px;
}

@media screen and (max-width: 600px) {
    .player-wrapper {
        position: relative;
        padding-top: 30.25%;
        height: 0vh;
    }
    .react-player {
        position: absolute;
        top: 28%;
        left: 0px;
        width: 97%!important;
        padding: 5px;
        height: auto;
    }
}