span.cls_003{font-family:Arial,serif;font-size:16.1px;color:rgb(254,255,255);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_003{font-family:Arial,serif;font-size:16.1px;color:rgb(254,255,255);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_005{font-family:Arial,serif;font-size:12.1px;color:rgb(43,42,41);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_005{font-family:Arial,serif;font-size:12.1px;color:rgb(43,42,41);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_006{font-family:Arial,serif;font-size:12.1px;color:rgb(43,42,41);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_006{font-family:Arial,serif;font-size:12.1px;color:rgb(43,42,41);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_007{font-family:Arial,serif;font-size:12.1px;color:rgb(35,104,88);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_007{font-family:Arial,serif;font-size:12.1px;color:rgb(35,104,88);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_008{font-family:Arial,serif;font-size:12.0px;color:rgb(43,42,41);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_008{font-family:Arial,serif;font-size:12.0px;color:rgb(43,42,41);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_009{font-family:Arial,serif;font-size:12.1px;color:rgb(57,114,99);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_009{font-family:Arial,serif;font-size:12.1px;color:rgb(57,114,99);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_010{font-family:Arial,serif;font-size:12.1px;color:rgb(57,114,99);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_010{font-family:Arial,serif;font-size:12.1px;color:rgb(57,114,99);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_011{font-family:Arial,serif;font-size:12.1px;color:rgb(43,42,41);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_011{font-family:Arial,serif;font-size:12.1px;color:rgb(43,42,41);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_012{font-family:Arial,serif;font-size:12.1px;color:rgb(43,42,41);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_012{font-family:Arial,serif;font-size:12.1px;color:rgb(43,42,41);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_013{font-family:Arial,serif;font-size:14.1px;color:rgb(43,42,41);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_013{font-family:Arial,serif;font-size:14.1px;color:rgb(43,42,41);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_014{font-family:Arial,serif;font-size:20.1px;color:rgb(254,255,255);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_014{font-family:Arial,serif;font-size:20.1px;color:rgb(254,255,255);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_015{font-family:Arial,serif;font-size:15.1px;color:rgb(37,36,34);font-weight:normal;font-style:normal;text-decoration: none}
div.cls_015{font-family:Arial,serif;font-size:15.1px;color:rgb(37,36,34);font-weight:normal;font-style:normal;text-decoration: none}
span.cls_002{font-family:Arial,serif;font-size:11.1px;color:rgb(43,42,41);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_002{font-family:Arial,serif;font-size:11.1px;color:rgb(43,42,41);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_017{font-family:Arial,serif;font-size:10.1px;color:rgb(43,42,41);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_017{font-family:Arial,serif;font-size:10.1px;color:rgb(43,42,41);font-weight:bold;font-style:normal;text-decoration: none}
span.cls_018{font-family:Arial,serif;font-size:13.1px;color:rgb(43,42,41);font-weight:bold;font-style:normal;text-decoration: none}
div.cls_018{font-family:Arial,serif;font-size:13.1px;color:rgb(43,42,41);font-weight:bold;font-style:normal;text-decoration: none}