.confirmOrderPage {
    height: 100vh;
    background-color: white;
    display: grid;
    grid-template-columns: 6fr 3fr;
}

.confirmOrderPage>div:last-child {
    border-left: 1px solid rgba(0, 0, 0, 0.247);
}

.printComponent {
    display: flex;
    justify-content: center;
    margin-top: -20px;
    font: 600 1.1vmax "Roboto";
    margin-bottom: 20px;
}

.printComponent>p {
    margin-left: 20px;
    margin-top: 2.5px;
}

.printComponent:hover {
    color: #065749;
    transform: scale(1.1);
}

.confirmshippingArea {
    padding: 5vmax;
    padding-bottom: 0%;
}

.confirmCartItems {
    padding-top: 0vmax;
    padding-bottom: 5vmax;
    /* padding-right: 5vmax; */
    padding-left: 5vmax;
    /* padding-top: 2vmax; */
}

.confirmshippingArea>p {
    font: 400 22px "Roboto";
}

.confirmshippingAreaBox {
    margin: 1.5vmax;
}

.cartitemholder {
    display: flex;
    flex-basis: 50%;
}

.cartitemholderimage,
.cartitemholdername,
.cartitemholdercat,
.cartitemholdersize,
.cartitemholderquantity {
    display: flex;
    flex-basis: 15%;
}

.confirmshippingAreaBox>div {
    display: flex;
    margin: 1vmax 0;
}

.confirmshippingAreaBox>div>p {
    font: 400 20px "Roboto";
    color: black;
}

.confirmshippingAreaBox>div>span {
    margin: 0 1vmax;
    font: 600 20px "Roboto";
    color: #575757;
}

.confirmCartItems>p {
    font: 400 22px "Roboto";
}

.confirmCartItemsContainer {
    max-height: 20vmax;
    overflow-y: auto;
}

.confirmCartItemsContainer>div {
    display: flex;
    font: 400 18px "Roboto";
    align-items: center;
    justify-content: space-between;
    margin: 1vmax 0;
}

.confirmCartItemsContainer>div>div>img {
    /* width: 3vmax; */
    height: 50px;
}

.confirmCartItemsContainer>div>a {
    color: #575757;
    margin: 0 2vmax;
    width: 60%;
    text-decoration: none;
}

.confirmCartItemsContainer>div>span {
    font: 100 20px "Roboto";
    color: #5e5e5e;
}

.orderSummary {
    padding: 7vmax;
}

.orderSummary>p {
    text-align: center;
    font: 400 20px "Roboto";
    border-bottom: 1px solid rgba(0, 0, 0, 0.267);
    padding: 1vmax;
    width: 100%;
    margin: auto;
    box-sizing: border-box;
}

.orderSummary>div>div {
    display: flex;
    font: 300 15px "Roboto";
    justify-content: space-between;
    margin: 2vmax 0;
}

.orderSummary>div>div>span {
    color: rgba(0, 0, 0, 0.692);
}

.orderSummaryTotal {
    display: flex;
    font: 300 20px "Roboto";
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.363);
    padding: 2vmax 0;
}

.orderSummary>button {
    background-color: #065749;
    color: white;
    width: 100%;
    padding: 1vmax;
    border: none;
    margin: auto;
    cursor: pointer;
    transition: 0.5s;
    font: 400 20px "Roboto";
}

.orderSummary>button:hover {
    background-color: rgb(192, 71, 50);
}

.PrintIcon {
    position: relative;
    transform: scale(2.0);
}

@media screen and (max-width: 800px) {
    .cartitemholderimage,
    .cartitemholdername,
    .cartitemholdercat,
    .cartitemholdersize,
    .cartitemholderquantity>span {
        display: flex;
        flex-basis: 50%;
        font-size: 15px;
    }
    .confirmshippingAreaBox>div:nth-child(1) {
        display: flex;
        flex-direction: column;
        margin: 1vmax 0;
    }
    confirmCartItems {
        padding-top: 0vmax;
        padding-bottom: 5vmax;
        padding-left: 5vmax;
        padding-right: 0vmax;
        padding-bottom: 0%;
        width: 80%;
    }
    .confirmshippingArea {
        padding-top: 5vmax;
        padding-bottom: 5vmax;
        padding-left: 5vmax;
        padding-right: 0vmax !important;
        padding-bottom: 0%;
        width: 80%;
    }
    /* .confirmCartItem {
        padding-top: 5vmax;
        padding-bottom: 5vmax;
        padding-bottom: 0%;
    } */
    .confirmOrderPage {
        grid-template-columns: 1fr;
        height: unset;
    }
    .confirmOrderPage>div:last-child {
        border-left: 0;
        border-top: 1px solid rgba(0, 0, 0, 0.247);
    }
    .confirmshippingArea>p {
        font: 400 20px "Roboto";
    }
    .confirmshippingAreaBox>div {
        display: flex;
        margin: 20px 0;
    }
    .confirmshippingAreaBox>div>p {
        font: 400 15px "Roboto";
    }
    .confirmshippingAreaBox>div>span {
        font: 100 15px "Roboto";
    }
    .confirmCartItems>p {
        font: 400 20px "Roboto";
    }
    .confirmCartItemsContainer {
        max-height: 50vw;
    }
    .confirmCartItemsContainer>div {
        font: 400 15px "Roboto";
        width: 100%;
        margin: 4vw 0;
    }
    .confirmCartItemsContainer>div>img {
        width: 10vw;
    }
    .confirmCartItemsContainer>div>a {
        margin: 0;
        width: 30%;
    }
    .confirmCartItemsContainer>div>div>span {
        font: 100 15px "Roboto";
    }
    .orderSummary {
        padding: 12vw;
    }
    .orderSummary>p {
        font: 400 20px "Roboto";
        padding: 15px;
    }
    .orderSummary>div>div {
        font: 300 15px "Roboto";
    }
    .orderSummaryTotal {
        font: 300 15px "Roboto";
        padding: 5vw 0;
    }
    .orderSummary>button {
        padding: 4vw;
        margin: 4vw auto;
        font: 400 15px "Roboto";
    }
}

@media screen and (max-width: 520px) {
    .confirmCartItemsContainer>div {
        font: 400 4vw "Roboto";
        display: flex;
        flex-wrap: wrap;
        width: 100%;
        margin: 4vw 0;
    }
}