.productsHeading {
    margin: 2vmax auto;
    width: 15vw;
    border-bottom: 1px solid rgba(0, 0, 0, 0.171);
    padding: 2vmax;
    color: rgba(0, 0, 0, 0.678);
    font: 500 25px "Roboto";
    text-align: center;
}

.products {
    display: flex;
    flex-wrap: wrap;
    padding: 0 5vmax;
    justify-content: center;
    min-height: 30vh;
    margin-top: 50px;
}

.paginationBox {
    display: flex;
    justify-content: center;
    margin: 6vmax;
}

.pagination {
    display: flex;
    justify-content: center;
    padding: 0;
}

.page-item {
    background-color: rgb(255, 255, 255);
    list-style: none;
    border: 1px solid rgba(0, 0, 0, 0.178);
    padding: 1vmax 1.5vmax;
    transition: all 0.3s;
    cursor: pointer;
}

.page-item:first-child {
    border-radius: 5px 0 0 5px;
}

.page-item:last-child {
    border-radius: 0 5px 5px 0;
}

.page-link {
    text-decoration: none;
    font: 300 15px "Roboto";
    color: rgb(80, 80, 80);
    transition: all 0.3s;
}

.page-item:hover {
    background-color: rgb(230, 230, 230);
}

.page-item:hover .page-link {
    color: rgb(0, 0, 0);
}

.pageItemActive {
    background-color: #065749;
}

.pageLinkActive {
    color: white;
}

.filterBox {
    display: flex;
    flex-direction: row;
    flex-basis: 100%;
    width: 10vmax;
    /* position: absolute; */
    top: 10vmax;
    left: 4vmax;
    width: 70%;
    margin-left: 15%;
}

.filSize {
    flex-basis: 35%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25%;
}

.filCategories {
    flex-basis: 100%;
}


/* .filRatings{
  flex-basis: 25%;
} */

.categoryBox {
    padding: 0%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    font: 400 20px "Roboto";
}

.category-link {
    list-style-type: none;
    /* background: white; */
    height: 100%;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    font: 400 20px "Roboto";
    cursor: pointer;
}

.category-link:hover {
    color: #065749;
}

.filterBox>fieldset {
    border: 1px solid rgba(0, 0, 0, 0.329);
}

@media screen and (max-width: 600px) {
    .filterBox {
        width: 20vmax;
        position: static;
        margin: auto;
    }
    .page-link {
        font: 300 25px "Roboto";
    }
    .category-link {
        font: 400 15px "Roboto";
    }
    .categoryBox {
        padding: 0%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        position: relative;
        font: 400 15px "Roboto";
    }
}

@media screen and (max-width: 800px) {
    .categoryBox {
        padding: 0%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        position: relative;
        font: 400 15px "Roboto";
        margin-left: -25px;
    }
}