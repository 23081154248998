.sidebar {
    background-color: rgb(255, 255, 255);
    display: flex;
    flex-direction: column;
    width: 0;
    /* padding: 4rem 0; */
}

.sidebar>a:first-child {
    padding: 0;
}

.sidebar>a>img {
    margin-top: 50px;
    width: 70%;
    transition: all 0.5s;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-left: 30px;
}

.sidebar>a>img:hover {
    filter: drop-shadow(0 0 10px #065749);
}

.sidebar a {
    text-decoration: none;
    color: rgba(0, 0, 0, 0.493);
    font: 200 1rem "Roboto";
    padding: 2rem;
    transition: all 0.5s;
}

.sidebar a:hover {
    /* color: #065749;
    transform: scale(1.1); */
}

.sidebar a>P {
    display: flex;
    align-items: center;
}

.sidebar a>p>svg {
    margin-right: 0.5rem;
}

.MuiTypography-root {
    /* background-color: #fff !important; */
}