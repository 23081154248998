.orderSuccess {
  margin: auto;
  text-align: center;
  padding: 10vmax;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.orderSuccess > svg {
  font-size: 7vmax;
  color: #065749;
}
.orderSuccess > p {
  font-size: 2vmax;
}
.orderSuccess > button{
  background-color: #065749;
  color: white;
  width: 20%;
  padding: 1vmax;
  border: none;
  margin: auto;
  cursor: pointer;
  transition: 0.5s;
  font: 400 1vmax "Roboto";
}
.orderSuccess > button:hover {
  background-color: rgb(192, 71, 50);
}

.orderSuccess > a {
  background-color: #065749;
  color: white;
  border: none;
  padding: 1vmax 3vmax;
  cursor: pointer;
  font: 400 1vmax "Roboto";
  text-decoration: none;
  margin: 2vmax;
}

@media screen and (max-width: 600px) {
  .orderSuccess > a {
    padding: 3vw 6vw;
    font: 400 4vw "Roboto";
    margin: 2vmax;
  }

  .orderSuccess > svg {
    font-size: 20vw;
  }
  .orderSuccess > p {
    margin: 2vmax;
    font-size: 5vw;
  }
}
